.ModvaridFooter {
    max-width: max-content;
    height: 630.5px;
    background-color: rgb(167, 139, 104);

}

.top {
    width: 100%;
    height: 185px;
}

.topWebTitle {
    width: 100%;
    height: 50%;
    display: flex;
    background-color: rgb(167, 139, 104);
}

.topBottom {
    width: 100%;
    height: 50%;
    background-color: bisque;
}

.topBottom>div {
    background-color: rgb(167, 139, 104);
    width: 100%;
    height: 100%;
    border-top-left-radius: 60px;

}

.topWebTitleLeft {
    width: 70%;
    height: 100%;
    border-bottom-right-radius: 40px;
    background-color: bisque;
}

.topWebTitleRight {
    width: 30%;
    height: 100%;
    background-color: bisque;
}

.topWebTitleRight>div {
    height: 100%;
    width: 100%;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    background-color: rgb(167, 139, 104);
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 36px;
}

.topWebTitleRight>div>img {
    max-width: 300px;
    max-height: 47px;
}

.bootom {
    width: 100%;
    min-height: 446px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding-left: 62.5px;
    padding-right: 62.5px; */
    background-color: red;
}

.bootomTop {
    width: 100%;
    height: 364px;
    background-color: rgb(117, 33, 33);
    padding-bottom: 62px;
}

.bootomTopTop {
    max-width: 100%;
    min-height: 280px;
    background-color: salmon;
    display: flex;
}

.bootomTopTopLeft {
    width: 393px;
    height: 100%;
    background-color: red;
}

.bootomTopTopLefTitle {
    width: 100%;
    height: 200px;
    background-color: burlywood;
}

.bootomTopTopLefTitle>h3 {
    font-weight: 500;
    font-size: 44px;
    line-height: 64px;
    color: rgba(128, 94, 57, 1);
}

.bootomTopTopLefTitle>div> {
    margin-top: 24px;
    display: flex;
}

.bootomTopTopLefTitle>div>input {
    width: 355px;
    height: 48px;
    background-color: white;
    border-radius: 100px;
    gap: 10px;
    padding: 8px 8px 8px 20px;
}

.bootomTopTopLefTitle>div>div {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: rgb(167, 139, 104);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin-top: -40px;
    right: 50px;
    z-index: 2;
}

.bootomTopTopLefSosicalIcon {
    width: 100%;
    height: 80px;
    position: relative;
}

.bootomTopTopLefSosicalIcon>div {
    position: absolute;
    bottom: 0;
    display: flex;
}

.bootomTopTopLefSosicalIcon>div>div {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: rgb(167, 139, 104);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 3px;
}

.bootomTopTopRight {
    max-width: 923px;
    height: 100%;
    padding-left: 138px;
    padding-right: 197px;
    padding-bottom: 22px;
}

.bootomTopTopRightIn {
    max-width: 558px;
    min-height: 258px;
    background-color: red;
    display: grid;
    grid-template-columns: auto auto auto;
}

.bootomTopTopRightIn>div:not(:first-child) {
    height: auto;
    margin-left: 76px;
}

.bootomTopTopRightIn>div>p {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: rgba(177, 177, 177, 1);
}

.bootomTopTopRightIn>div>div {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
}

.bootomTopTopRightIn>div>div>a:not(:first-child) {
    margin-top: 10px;
}

.bootomTopBottom {
    margin-top: 40px;
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: space-between;
}

.bootomTopBottom>p {
    margin-top: 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: rgba(77, 79, 81, 1);
}

.bootomTopBottom>div {
    display: flex
}

.chakraSelcetDropdown1 {
    width: 100px;
    border-right: none !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    color: white;
    background-color: rgb(167, 139, 104);
}

.chakraSelcetDropdown2 {
    width: 100px;
    border-left: none !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    color: white;
    background-color: rgb(167, 139, 104);
}


.bootomBootom {
    margin-top: 20px;
    width: 100%;
    height: 62px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bootomBootom>div {
    width: 346px;
    height: 34px;
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bootomBootom>div>img {
    object-fit: cover;
}

.bBimg1 {
    width: 79.09px;
    height: 20px;
}

.bBimg2 {
    width: 56.52px;
    height: 20px;
}

.bBimg3 {
    width: 71.25px;
    height: 20px;
}

.bBimg4 {
    width: 44px;
    height: 34px;
}