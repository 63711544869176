.MainContainer {
    width: 100%;
    height: max-content;
    color: #b6c2cf;
}

.InviteButton {
    display: flex;
    align-items: center;
    gap: 7px;
    background-color: #579dff;
    max-width: max-content;
    color: #1d2125;
    border-radius: 4px;
    padding: 6px 12px;
    height: max-content;
    font-weight: 600;
    height: 32px;
    font-size: 14px;
}

.Modal {
    background-color: #1e2225 !important;
    color: #b6c2cf !important;
}

.Input {
    border-radius: 0;
    box-shadow: none !important;
    border-color:none !important;
    border-radius: 3px !important;
}

.Input:active {
    border-color:#1e2225;
    box-shadow: #65727c !important;
}
.Input:focus-visible {
    border-color:none !important;
    box-shadow: #65727c !important;
}