.CommentSection {
    padding: 9px 12px;
    box-shadow: var(--ds-shadow-raised, 0px 1px 1px #091e4240, 0px 0px 1px #091e424f);
    border-radius: 8px;
    background-color: var(--ds-background-input, #22272b);
    width: 100%;
    color: #9fadbc;
    font-size: 14px;
    outline: none;
    margin: 0;
}

.UserName {
    margin: 0;
    color: #cdd0d2;
    font-size: 15px;
    font-weight: 600;
}

.details {
    margin: 0;
    color: #9fadbc;
    font-size: 13px;
    font-weight: 400;
}


.dot {
    margin: 0;
    color: #9fadbc;
    font-size: 13px;
    font-weight: 600;
}