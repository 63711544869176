.main {
  background-color: #101204;
  width: 270px;
  height: max-content;
  max-height: max-content;
  padding: 10px 10px;
  border-radius: 14px;
  border: 1px solid rgba(159, 173, 188, 0.253);
  color: #9fadbc;
}

.taskList {
  max-height: 72vh !important;
  overflow-y: auto;
}

.CoverContent {
  height: 100px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.main h2 {
  font-size: 18px;
  font-weight: 600;
  color: #bec6cf;
}

.addTaskButton {
  display: flex;
  gap: 5px;
  align-items: center;
  color: #9fadbc;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}

.addTaskButton p {
  margin: 0 !important;
}

.createTaskButton {
  background-color: #67a6ff;
  border: none;
  font-size: 14px;
  color: #1d2125 !important;
  border-radius: 4px;
  font-weight: 600;
  padding: 4px 10px;
}

.inputTask {
  border-radius: 6px;
  background-color: #22272B;
  border: 1px solid #74879C;
  padding: 3px 8px;
  color: #cee2fd;
  width: 100%;
}

.closeButton {
  display: flex;
  align-items: center;
  color: #cee2fd8c;
  font-weight: 300;
}

.createTaskContainer {
  display: flex;
  height: max-content;
  flex-direction: column;
  gap: 10px;
  background-color: #1d2125;
  border-radius: 6px;
  padding: 9px;
}

.createTaskButtonsContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.CreateCardContainer {
  padding: 10px 0 0px 0px;
}

.Details {
  max-width: 100% !important;
  max-height: max-content !important;
  min-height: max-content !important;
}

.DetailsContainer {
  padding: 0 15px;
}

.title {
  color: #9fadbc;
  font-weight: 600;
  font-size: 16px;
}

.headerTitle {
  color: #9fadbc;
  font-weight: 600;
}

.CommentSection {
  padding: 9px 12px;
  box-shadow: var(--ds-shadow-raised, 0px 1px 1px #091e4240, 0px 0px 1px #091e424f);
  border-radius: 8px;
  background-color: var(--ds-background-input, #22272b);
  width: 100% ;
  color: #9fadbc;
  font-size: 14px;
  outline: none;
}

.EditButton {
  color: #9fadbc !important;
  font-weight: 600 !important;
  background-color: #5b5e6136 !important;
  padding: 7px 10px !important;
  border-radius: 3px !important;
  height: max-content !important;
}

.EditButtonDesc {
  font-weight: 600 !important;
  padding: 7px 10px !important;
  border-radius: 3px !important;
  height: max-content !important;
}

.OptionSellectButton {
  display: flex;
  align-items: center;
  background-color: #3B444C;
  cursor: pointer;
  padding: 4px 8px;
  width: 100%;
  font-weight: 700;
  font-size: 14px;
  border-radius: 5px;
  color: #b6bfc9 !important;
  border: 1px solid #808d971d;
}

.OptionSellectButton p {
  margin: 0;
}

.CoverPopover {
  border: none !important;
  background-color: #23282b !important;
  padding: 10px 20px 20px 20px !important;
}

.ColorContaier {
  width: 50px;
  height: 32px;
  cursor: pointer;
}

.ColorContaier:hover {
  border: 2px solid rgb(87, 157, 255);
}

.OptionSellectButton:hover {
  background-color: #4f5a64;
}

.LeftContainer {
  display: flex;
  gap: 7px;
  flex-direction: column;
}

.ModalBody {
  background-color: #323940 !important;
  max-width: 760px !important;
  height: max-content;
  padding-bottom: 20px;
  border-radius: 13px !important;
}

.inputTaskArea {
  border-radius: 6px;
  background-color: #22272B;
  border: 1px solid #74879c6c;
  outline: none;
  padding: 3px 8px;
  color: #cee2fd;
  width: 100%;
  margin: 10px 0;
  min-height: 160px;
}

.inputTaskAreaClosed {
  border-radius: 6px;
  background-color: #262d316b;
  border: 1px solid #74879c6c;
  outline: none;
  padding: 3px 8px;
  color: #cee2fd;
  width: 100%;
  margin: 10px 0;
  min-height: 160px;
}



.Text {
  color: #9fadbc;
  font-weight: 400;
  font-size: 15px;
  padding: 10px 0;
}

.MenuTitle {
  color: #9fadbc;
  font-weight: 700;
  font-size: 12px;
  margin: 0;
}

.InputCheck {
  height: 30px;
  padding: 5px;
}

.InputCheck2 {
  background-color: none !important;
}

.ChecklistModal {
  padding: 12px;
  width: 304px;
  height: max-content;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Noto Sans', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-display: swap;
  background-color: var(--ds-surface-overlay, #282e33) !important;
  border-radius: 8px;
  color: var(--ds-text, #b6c2cf) !important;
  box-shadow: var(--ds-shadow-overlay, 0px 8px 12px #29282826, 0px 0px 1px #afb2b64f);
  border: 1px solid #b6c2cf0c !important;
  box-sizing: border-box;
  outline: 0;
  overflow: hidden;
}

.PopoverMembers {
  padding: 12px;
  width: 304px;
  height: max-content;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Noto Sans', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-display: swap;
  background-color: var(--ds-surface-overlay, #282e33) !important;
  border-radius: 8px;
  color: var(--ds-text, #b6c2cf) !important;
  box-shadow: var(--ds-shadow-overlay, 0px 8px 12px #29282826, 0px 0px 1px #afb2b64f);
  border: 1px solid #b6c2cf0c !important;
  box-sizing: border-box;
  outline: 0;
  overflow: hidden;
  
}

.DateModal button {
  background-color: var(--ds-link, #579dff);
  color: var(--ds-text-inverse, #1d2125);
  padding: 8px 20px;
  margin-top: 14px;
  border-radius: 4px;
}
.Button:hover{
  background-color: #4f5a64;
}

.DateModal {
  padding: 12px;
  width: max-content !important;
  height: max-content !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Noto Sans', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-display: swap;
  background-color: var(--ds-surface-overlay, #282e33) !important;
  border-radius: 8px;
  color: var(--ds-text, #b6c2cf) !important;
  box-shadow: var(--ds-shadow-overlay, 0px 8px 12px #29282826, 0px 0px 1px #afb2b64f);
  border: 1px solid #b6c2cf0c !important;
  box-sizing: border-box;
  outline: 0;
  overflow: hidden;
}

.CheckListHeader {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: -0.003em;
  text-align: center;
  margin: 0;
}

.ChecklistModal label {
  padding-top: 10px;
  font-size: 14px !important;
  font-weight: 600;
  letter-spacing: -0.003em;
  color: #9fadbc;
}

.MembersTitle {
  padding: 10px 0;
  font-size: 13px !important;
  font-weight: 500;
  letter-spacing: -0.003em;
  color: #9fadbc;
  margin: 0;
}

.ChecklistModal input {
  background-color: #23282b !important;
  outline: none !important;
}

.ChecklistModal button {
  background-color: var(--ds-link, #579dff);
  color: var(--ds-text-inverse, #1d2125);
  padding: 8px 20px;
  margin-top: 14px;
  border-radius: 4px;
}

.ChecklistMainConatiner {
  height: max-content;
  align-items: center;
  gap: 25PX;
  width: 100%;
  display: flex;
}

.ChecklistMain button {
  margin: 0;
  height: max-content;
  width: max-content;
  padding: 6px 12px;
  background-color: var(--ds-background-neutral, #A1BDD914);
  color: var(--ds-text, #b6c2cf);
  border-radius: 3px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 14px;
  font-weight: 400;
  user-select: none;
}

.ChecHeader {
  display: flex;
  align-items: center;
  height: max-content;
  justify-content: space-between;
  width: 100%;
}

.ChecklistMainConatiner {
  height: max-content;
  align-items: center;
  gap: 25PX;
  width: 100%;
  display: flex;
}

.XmarkIcon {
  cursor: pointer;
}

.InputCheck {
  padding: 17px 12px;
}


.ChecHeader p {
  margin: 0;
  user-select: none;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}

.DueDateContainer {
  color: #9fadbc;
}

.DueDateTitle {
  font-weight: 600;
  font-size: 13px;
  padding: 0;
  margin: 0;
}

.DueDate {
  font-weight: 600 !important;
  font-size: 17px !important;
  padding: 0;
  margin: 0;
}

.attachment {
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-direction: column !important;
}

.attachmentItem {
  padding: 5px 7px;
  border-radius: 10px;
  background-color: #4f5a645b;
  color: #9fadbc;
  font-size: 17px;
  width: max-content;
}
.DropDownOptions{
  width: 100% !important;
  
}
.SellectOption {
  background-color: #23282b !important;
  border: none;
  padding: 10px 10px;
  width: 100% !important;
}
.SelectedOption{
  width: 100% !important;
  color: white;
  border-radius: 5px;
}
.Options{
  padding: 2px 5px !important;
  border: 1px black !important;
  list-style: none;
  margin: 0 !important;
  margin-left: 0;
  width: 100%;
  margin-bottom: 0;
}
.DropDownSelect{
  min-width: 100% !important;
}
.Options:hover{
cursor: pointer;
}

.Input {
  background-color: transparent;
  border: 1px solid #4f5a64;
  outline: none;
  border-radius: 3px;
  width: 100%;
  padding: 0px 10px;
}

.ColorOption {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #b6c2cfd6 !important;

}

.InputCheckError {
  border: 1px solid red;
}
.mainCustomfieldContainer{
  
}
.CustomFiled{
  max-width: 30% !important;
  min-width: 160px !important;
  height: max-content;
}
.CustomFiled input{
  max-width: 130px;
  min-width: 30%;
}
.DropDownSellect {
  height: 27px !important;
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  border-radius: none !important;
}

.Option {
  cursor: pointer !important;
  text-decoration: none;
}
.DropDown{
  padding: 0px 5px;
  color: #9fadbc;
  text-decoration: none;
  list-style: none;
  margin: 0;
}