.MainContainer {
    padding: 32px;
    width: 100%;
    height: 100%;
}

.SettingsContainer {
    max-width: 800px;
    margin: auto;
    height: max-content;
    color: #b6c2cf;
}

.SettingsHeader {
    font-weight: 600;
    font-size: 20px;
}

.Button {
    min-width: max-content !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    color: #9fadbc !important;
    background-color: #38414868 !important;
    padding: 5px 10px;
    border-radius: 2px !important;
    border: 1px solid #808d97 !important;
}

.UploadImageButton {
    padding: 2px 0px;
    width: 210px;
    font-size: 15px !important;
    font-weight: 500 !important;
    color: #9fadbc !important;
    background-color: #38414868 !important;
    border-radius: 2px !important;
    border: 1px solid #808d97 !important;
}
.ButtonsContainer *{
    margin: 0 !important;
}
.ButtonsContainer {
   min-width: max-content;
   gap: 10px;
   align-items: center;
}
.ButtonDelete {
    min-width: max-content !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    color: #cf3737 !important;
    background-color: #38414868 !important;
    padding: 5px 10px;
    border-radius: 2px !important;
    border: 1px solid #cf3737 !important;
}

.DeleteSection {
    align-items: center;
    height: max-content;
    justify-content: space-between;
    padding: 10px 0;
}

.DeleteSection p {
    margin: 0;
}