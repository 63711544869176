.main {
  display: flex;
  gap: 15px;
  width: 100%;
}

.BoardListContainer {
  display: flex;
  gap: 15px;
  height: 88vh;
  overflow-y: auto;
  padding: 10px;
  width: 100%;
}

.AddListButton {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #172b4d;
  background-color: #ffffff3d;
  padding: 10px;
  border-radius: 6px;
  padding: 20px 16px;
  min-width: 230px !important;
  cursor: pointer;
  transition: all 300ms;
  height: max-content;
}


.AddListButton p {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
}

.CreateBoardButton {
  background-color: #67a6ff;
  border: none;
  font-size: 14px;
  color: #1d2125 !important;
  border-radius: 4px;
  font-weight: 600;
  padding: 4px 10px;
}

.InputList {
  border-radius: 6px;
  background-color: #22272B;
  border: 1px solid #74879C;
  padding: 3px 8px;
  color: #cee2fd;
}

.CloseButton {
  display: flex;
  align-items: center;
  color: #cee2fd8c;
  font-weight: 300;
}

.CreateListContainer {
  transition: all 300ms;
  display: flex;
  height: max-content;
  flex-direction: column;
  gap: 10px;
  background-color: #1d2125;
  border-radius: 6px;
  padding: 9px;
}

.CreateListButtonsContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}