.ListTitle {
    color: #9fadbc;
    font-weight: 600;
    font-size: 18px;
    margin: 0;
}

.Button {
    color: #9fadbc !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    background-color: #5b5e6136 !important;
    padding: 3px 5px !important;
    border-radius: 3px !important;
    height: max-content !important;
    border: 1px solid #4f5a64;
}


.ChecklistTitle {
    color: #9fadbc;
    font-size: 15px;
    margin: 0;
}

.Input {
    background-color: transparent;
    border: 1px solid #4f5a64;
    color: #9fadbc !important;
    outline: none;
    border-radius: 3px;
    padding: 0px 10px;
    margin: 0 10px;
    width: 100% !important;
}

.Button2 {
    color: #9fadbc !important;
    font-weight: 600 !important;
    background-color: #5b5e6136 !important;
    padding: 6px 10px !important;
    border-radius: 3px !important;
    height: max-content !important;
}

.HiddenChecklistItemsContainer {
    width: 100%;
    height: max-content;
    background-color: #5b5e6136;
    border: 1px solid #4f5a64;
    color: #9fadbc;
    padding: 5px;
}

.HiddenTitle {
    text-transform: capitalize;
    color: aliceblue;
    margin: 0;
}