body {
    color: #9FADBC;
    min-height: 100vh;
    background-color: #212529 !important;
}

.btn-close {
    background-color: none !important;
}

/* For WebKit-based browsers (Chrome, Safari) */
*::-webkit-scrollbar {
    width: 12px;
    /* Width of the scrollbar */
}

*::-webkit-scrollbar-track {
    background: #22272B;
    /* Color of the scrollbar track */
}

*::-webkit-scrollbar-thumb {
    background-color: #a1abba;
    /* Color of the scrollbar thumb */
    border-radius: 4px;
    /* Rounded corners of the scrollbar thumb */
    border: 2px solid #22272B;
    /* Creates padding around the scrollbar thumb */
}

.dropdown-menu {
    padding: 0;
    border-radius: 5px;
}

.Button {
    min-width: max-content !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    color: #9fadbc ;
    background-color: #38414868;
    padding: 5px 10px;
    border-radius: 2px !important;
    border: 1px solid #808d97 ;
}
ol, ul {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
}
/* For Firefox */
html,
body {
    scrollbar-color: #818791 #1c1f22b4;
    /* Color of the thumb and track */
    scrollbar-width: thin;
    /* Width of the scrollbar */
}

.nav-link.active {
    color: #3169ca !important;
    background-color: #1c2b41 !important;
}

.create-workspace-close {
    --bs-btn-close-bg: url(data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2344546f'><path%20d='M.293.293a1%201%200%200%201%201.414%200L8%206.586%2014.293.293a1%201%200%201%201%201.414%201.414L9.414%208l6.293%206.293a1%201%200%200%201-1.414%201.414L8%209.414l-6.293%206.293a1%201%200%200%201-1.414-1.414L6.586%208%20.293%201.707a1%201%200%200%201%200-1.414z'/></svg>);
    --bs-btn-hover-color: unset;
    --bs-btn-hover-bg: none;
    --bs-btn-hover-border-color: none;
    --bs-btn-active-color: #44546f;
    --bs-btn-active-bg: none;
    --bs-btn-active-border-color: none;
    --bs-btn-active-shadow: none;
    --bs-btn-close-color: #000;
    --bs-btn-close-opacity: 0.5;
    --bs-btn-close-hover-opacity: 0.75;
    --bs-btn-close-focus-shadow: 0;
    --bs-btn-close-focus-opacity: 1;
    --bs-btn-close-disabled-opacity: 0.25;
    --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
}

.css-g1hrzp {
    background-color: #22272B !important;
    --select-bg: #22272B !important;
    --input-border-radius: : 2px !important;
    border-radius: 2px !important
}
/* Custom styles for the select element */
.custom-select {
    /* Remove the default blue background */
    background-color: transparent;
  }
  
  /* Style the options within the select element */
  .custom-select option {
    /* Custom styles for each option */
    background-color: inherit;
  }
  
  /* Remove the default blue highlight on hover */
  .custom-select option:hover {
    background-color: inherit; /* Inherits the background color defined in your JSX */
    color: inherit; /* Optional: inherit text color */
  }
    