.main{
    background-color: #22272B;
    width: 100%;
    height: max-content;
    min-height: max-content;
    margin: 10px 0;
    border-radius: 6px;
    color: #b6c2cf;
    user-select: none;
}
.main p{
   margin: 0;
}
.CoverDiv{
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    height: 30px;
    width: 100%;
    background-color: navajowhite;
}
.main2{
    background-color: #22272B;
    width: 100%;
    height: 70px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 0 7px 7px 7px;
    color: #b6c2cf;
    user-select: none;
}
.Content{
    padding: 10px 7px;
}
.TaskTitle{
    margin: 0;
    margin-bottom: 5px;
}