.MainContainer::-webkit-scrollbar {
    width: 8px;
    /* Width of the scrollbar */
    border-radius: 5px;
}
.MainContainer {
    display: flex;
    width: 100% ;
    margin: auto
}
.MainContainer::-webkit-scrollbar-track {
    background: #22272B;
    /* Color of the scrollbar track */
}
.Text{
    font-size: 20px;
    text-align: center;
    padding: 70px 0;
}

.MainContainer::-webkit-scrollbar-thumb {
    background-color: #a1abba;
    /* Color of the scrollbar thumb */
    border-radius: 4px;
    /* Rounded corners of the scrollbar thumb */
    border: 2px solid #22272B;
    /* Creates padding around the scrollbar thumb */
    border-radius: 5px;
    display: none !important;
}
.notFound{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100% !important;
}
