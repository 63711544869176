.sideBarMenuWrapper {
    height: calc(95vh - 0.2rem);
    display: flex;
    max-width: 100%;
    border-right: rgb(159 173 188 / 24%) 1px solid;
    background-color: #161a1de6;
}

.MenuButton {
    text-align: start;
    font-size: 14px;
    background-color: transparent;
    border: none;
    border-radius: 0;
    width: 100%;
    color: #9fadbc;
    font-weight: 600;
}

.Selected {
    text-align: start;
    font-size: 14px;
    background-color: #ffffff4d;
    border: none;
    border-radius: 0;
    width: 100%;
    color: #9fadbc;
    font-weight: 600;
}

.MenuButtonIcon {
    width: 37px !important;
    margin-right: 5px;
    font-size: 14px;
}

/* ffffff4d */

.MenuButton:hover {
    background-color: #ffffff29;
    color: #9fadbc;
}

.notFound {
    width: 100%;
}

.sideBarMenu {
    height: 100%;
    width: 100%;
    background: transparent;
    color: #9fadbc;
    overflow: auto;
}

/* .sideBarMenu:first-child{
    margin-top: 1.75rem;
} */

.workspacePic {
    width: clamp(16px, 4.5vw, 32px);
}

.profilesWrapper {
    display: flex;
    margin-left: 0.5rem;
}

.accordionBtn,
.accordionBtn Button {
    border-radius: 0.07rem !important;
}

.accordionBtn h2 Button {
    color: #b6c2cf;
    font-weight: 600;
    background-color: transparent !important;
    box-shadow: none;
}

.accordionBtn Button {
    padding: 8px 13px;
    border-radius: 4px;
}

.accordionBtn h2 Button:not(.collapsed) {
    box-shadow: none;
    color: #b6c2cf;
}

.accordionBtn h2 Button:hover {
    background-color: #333c43 !important;
}

.accordionBtn h2 Button[aria-expanded='true'] {
    background-color: #1c2b41 !important;
}

.accordionBtn h2 Button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23b6c2cf'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordionBtn Button {
    --bs-btn-color: #b6c2cf;
    --bs-btn-bg: transparent;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: #b6c2cf;
    --bs-btn-hover-bg: #333c43;
    --bs-btn-hover-border-color: #333c43;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #b6c2cf;
    --bs-btn-active-bg: #1c2b41;
    --bs-btn-active-border-color: #1c2b41;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #b6c2cf;
    --bs-btn-disabled-bg: #293035;
    --bs-btn-disabled-border-color: #293035;
}

.accordionBtn {
    background-color: transparent;
    border: 0;
}

.sideBarMenuWorkspacePic {
    width: 32px;
    height: 32px;
    border-radius: 4px !important;
    object-fit: cover;
}

.contentWrapper {
    padding: 5rem;
}

.contentTopNavBar {
    border-bottom: 1px solid #384148;
    padding-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contentMain {
    margin-top: 3rem;
}

.Modal {
    background-color: #1e2225 !important;
    color: #b6c2cf !important;
}

.Button {
    border-radius: 4px;
    padding: 3px 12px;
    border: none;
    background-color: #579dff;
}

.ButtonCancel {
    border-radius: 4px;
    padding: 3px 12px;
    background-color: #333c43;
    border: none;
}