.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 32px;
}
.MainContainer{
  max-height: 71vh;
}
.Container{
  overflow-y: auto;
  max-height: 307px;
}
.contentTopNavBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contentTopNavBar Button {
  align-self: flex-start;
}
.WokspaceImage{
  width: 50px;
}
.SelectOption{
  font-weight: 600;
  color:#9fadbc;
  width: 100%;
}
.SellectWrapper{
  width: 100%;
  padding-right: 10px;
}
.SellectContainer{
  border-right: rgb(159 173 188 / 24%) 1px solid;;
  height: 50vh;
  margin-right: 20px;
  padding-right: 10px;
  max-width: 290px;
  min-width: 230px; 
}
.modalShareButton {
  margin-left: 0.5rem;
  --bs-btn-color: #1d2125;
  --bs-btn-bg: #579dff;
  --bs-btn-border-color: #579dff;
  --bs-btn-hover-color: #1d2125;
  --bs-btn-hover-bg: #78b0ff;
  --bs-btn-hover-border-color: #78b0ff;
  --bs-btn-focus-shadow-rgb: 0;
  --bs-btn-active-color: #1d2125;
  --bs-btn-active-bg: #579dff;
  --bs-btn-active-border-color: #579dff;
  --bs-btn-active-shadow: none;
  --bs-btn-disabled-color: #1d2125;
  --bs-btn-disabled-bg: #384148;
  --bs-btn-disabled-border-color: #384148;
  font-weight: 600;
  --bs-btn-padding-x: 1.25rem;
  --bs-btn-padding-y: 7px;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 0.9rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-radius: 6px;
}

.shareButton {
  margin-left: 1rem;
  --bs-btn-color: #1d2125;
  --bs-btn-bg: #579dff;
  --bs-btn-border-color: #579dff;
  --bs-btn-hover-color: #1d2125;
  --bs-btn-hover-bg: #78b0ff;
  --bs-btn-hover-border-color: #78b0ff;
  --bs-btn-focus-shadow-rgb: 0;
  --bs-btn-active-color: #1d2125;
  --bs-btn-active-bg: #579dff;
  --bs-btn-active-border-color: #579dff;
  --bs-btn-active-shadow: none;
  --bs-btn-disabled-color: #1d2125;
  --bs-btn-disabled-bg: #384148;
  --bs-btn-disabled-border-color: #384148;
  font-weight: 600;
  --bs-btn-padding-x: 0.7rem;
  --bs-btn-padding-y: 0.2rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 0.9rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-radius: 3px;
}

.workspaceLink {
  height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  align-items: center;
}

.workspaceLink > div {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
#navbarScrollingDropdown {
  width: 300px;
}
#GenerateLink {
  width: 200px;
  height: 60px;
  background-color: #384148;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 10px;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 100px;
}
#GenerateLink:hover {
  background-color: rgb(15, 166, 15);
  color: white;
}

.ThisGenerateLinkUrl {
  width: 80%;
  height: 170px;
  display: flex;
  flex-direction: column;
}

.copyinkgenerator {
  width: 120px;
  height: 40px;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  background-color: rgba(19, 186, 80, 0.2);
  font-size: 20px;
  border-radius: 4px;
}
.copyinkgenerator:hover {
  background-color: rgb(12, 217, 87);
  color: white;
}

.LinkTextarea {
  background-color: #384148;
  border-radius: 5px;
  border-bottom-right-radius: 45px;
}
.UserImage{
  width: 39px;
  border-radius: 50% !important;
}
.MemberContainer{
  display: flex;
  align-items: center;
}

.UserDetails  {
  margin: 0;
  color: #9fadbc;
  font-size: 14px;
}
.UserDetailsEmail{
  margin: 0;
  color: #d4d6d8;
  font-size: 19px;
}
.searchInput::placeholder {
  color: #b6c2cf; /* Placeholder text color */
}
.Inputinvite::placeholder {
  color: #b6c2cf; /* Placeholder text color */
}
.Inputinvite{
height: 45px;
width: 100%;
box-shadow: none;
border:  1px solid #b6c2cf60;
}
.searchInput{
  width: 200px;
}
.InviteLinkGenerateContainer{
  height: max-content;
  background-color: #1d2125 !important;
  border-radius: 4px;
}
.HeaderGenerateLink{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.HeaderGenerateLink h1{
margin: 0 !important; 
font-size: 25px !important;
color: #e5e9ee;
}
.InviteDesc {
  margin: 0 !important; 
  font-size: 15px !important;
  color: #e5e9ee;
  }
  .Button{
    min-width: max-content !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    color: #9fadbc !important;
    background-color: #38414868 !important;
    padding: 5px 10px ;
    border-radius: 2px !important;
    border: 1px solid #808d97 !important;
  }
  .Select{
    background-color: #384148;
    color: #b6c2cf;
  }
  .searchResults{
    background-color: #384148;
    border-radius: 3px;
  }
  .searchResultItem{
    padding: 5px;
    cursor: pointer;
  }