.sideBarMenuWrapper {
    height: 95vh;
    display: flex;
} 

.MainContainer { 
    width: 100%;
    height: 100vh;
}

.sideBarMenu {
    text-align: start;
    font-size: 14px;
    background-color: transparent;
    border: none;
    border-radius: 0;
    width: 100%;
    color: #9fadbc;
    font-weight: 600;
}
.BoardButton{
    text-align: start;
    font-size: 14px;
    background-color: transparent;
    border: none;
    border-radius: 0;
    width: 100%;
    color: #9fadbc;
    font-weight: 600;
    padding: 6px 0;
    width: 100%;
    margin: 0;
}
.Selected{
    text-align: start;
    font-size: 14px;
    background-color: #ffffff4d;
    border: none;
    border-radius: 0;
    width: 100%;
    color: #9fadbc;
    font-weight: 600;
    
}
.PopoverMembers {
    padding: 12px;
    width: 304px;
    height: max-content;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Noto Sans', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    font-display: swap;
    background-color: var(--ds-surface-overlay, #282e33) !important;
    border-radius: 8px;
    color: var(--ds-text, #b6c2cf) !important;
    box-shadow: var(--ds-shadow-overlay, 0px 8px 12px #29282826, 0px 0px 1px #afb2b64f);
    border: 1px solid #b6c2cf0c !important;
    box-sizing: border-box;
    outline: 0;
    overflow: hidden;
  }
.YourBoardText{
    margin: 0;
    padding: 6px;
    margin: 5px 0;
}
.BoardImage{
        width: 26px;
        height: 20px;
        border-radius: 4px !important;
        object-fit: cover;
}

.sideBarMenuWorkspaceName {
    border-bottom: 1px solid #384148;
}

.sideBarMenuWorkspaceName,
.sideBarMenuTopMenuWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    height: 56px;
}

.workspacePic {
    width: clamp(16px, 4.5vw, 32px);
}

.sideBarMenuTopMenuWrapper {
    background: rgb(30 32 37 / 37%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    z-index: 5;
    position: relative;
}

.sideBarMenuTopMenu {
    display: flex;
    color: #fff;
    justify-content: space-between;
    height: 75px;
    align-items: center;
}

.boardName {
    margin: 0;
    padding: 0.35rem;
    font-weight: 700;
}

[contenteditable]:focus,
[contenteditable]:focus-visible {
    border: 2px solid #388bff;
    border-radius: 0.5rem;
    background-color: rgba(145, 145, 220, 0.2);
    outline: none;
}

.workspacePrivacyDropdownWrapper {
    padding: 1px 0.8rem;
    display: flex;
    height: max-content;
    align-items: center;
    border-radius: 3px;
    margin-right: 0.5rem;
}

.ContentDropdown {
    background-color: #384148;
    width: 300px;
    height: max-content;
    padding: 10px;
    padding-bottom: 30px;
}
.DeleteModal{
    background-color: #22272B !important;
    color: #98A5B2 !important ;
}
.DeleteButton{
    color: #98A5B2 !important;
    border: 1px solid  #98A5B2 !important;

}
.label{
    background-color: rebeccapurple;
    width: 100%;
    height: 32px;
    padding: 5px;
}

.FilterMenuHeader {
    color: var(--ds-text-subtle, #9fadbc);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.003em;
    padding: 0;
    display: flex;
    margin: 0;
}

.FilterDesc {
    font-size: 13px !important;
    padding: 5px 0;
    margin: 0;
    margin-bottom: 5px;
}

.FilterInput {
    padding: 6px 10px;
    font-size: 17px;
    background-color: #292d30;
    border-radius: 3px;
    border: 1px solid #9eacba;
    margin: 2px 0;
    color: #9fadbc;
}
.workspacePrivacyDropdown{
    padding: 2px 5px;
    border-radius: 3px;
}
.workspacePrivacyDropdown:hover {
    background-color: #b6b6b63b;
}

.workspacePrivacyDropdown button {
    --bs-btn-color: #fff;
    font-weight: 500;
    --bs-btn-bg: transparent;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: transparent;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-focus-shadow-rgb: 0;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: transparent;
    --bs-btn-active-border-color: transparent;
    --bs-btn-active-shadow: none;
    --bs-btn-disabled-color: #1d2125;
    --bs-btn-disabled-bg: #384148;
    --bs-btn-disabled-border-color: #384148;
    --bs-btn-padding-x: 0rem;
    --bs-btn-padding-y: 0rem;
}

#workspace-privacy-dropdown-wrapper .dropdown .dropdown-menu[data-bs-popper] {
    margin-top: 19px;
}

.workspacePrivacyDropdown button::after {
    content: none;
}

.workspacePrivacyDropdown span,
.workspacePrivacyDropdown p {
    font-size: 0.9rem;
    color: #9eacba;
}

.profilesWrapper {
    display: flex;
    margin-left: 0.5rem;
}

.shareButton {
    margin-left: 1rem;
    --bs-btn-color: #1d2125;
    --bs-btn-bg: #579dff;
    --bs-btn-border-color: #579dff;
    --bs-btn-hover-color: #1d2125;
    --bs-btn-hover-bg: #78b0ff;
    --bs-btn-hover-border-color: #78b0ff;
    --bs-btn-focus-shadow-rgb: 0;
    --bs-btn-active-color: #1d2125;
    --bs-btn-active-bg: #579dff;
    --bs-btn-active-border-color: #579dff;
    --bs-btn-active-shadow: none;
    --bs-btn-disabled-color: #1d2125;
    --bs-btn-disabled-bg: #384148;
    --bs-btn-disabled-border-color: #384148;
    font-weight: 600;
    --bs-btn-padding-x: 0.7rem;
    --bs-btn-padding-y: 0.2rem;
    --bs-btn-font-family: ;
    --bs-btn-font-size: 0.9rem;
    --bs-btn-font-weight: 400;
    --bs-btn-line-height: 1.5;
    --bs-btn-color: var(--bs-body-color);
    --bs-btn-border-width: var(--bs-border-width);
    --bs-btn-border-radius: 3px;
}

.modalShareButton {
    margin-left: 0.5rem;
    --bs-btn-color: #1d2125;
    --bs-btn-bg: #579dff;
    --bs-btn-border-color: #579dff;
    --bs-btn-hover-color: #1d2125;
    --bs-btn-hover-bg: #78b0ff;
    --bs-btn-hover-border-color: #78b0ff;
    --bs-btn-focus-shadow-rgb: 0;
    --bs-btn-active-color: #1d2125;
    --bs-btn-active-bg: #579dff;
    --bs-btn-active-border-color: #579dff;
    --bs-btn-active-shadow: none;
    --bs-btn-disabled-color: #1d2125;
    --bs-btn-disabled-bg: #384148;
    --bs-btn-disabled-border-color: #384148;
    font-weight: 600;
    --bs-btn-padding-x: 1.25rem;
    --bs-btn-padding-y: 7px;
    --bs-btn-font-family: ;
    --bs-btn-font-size: 0.9rem;
    --bs-btn-font-weight: 400;
    --bs-btn-line-height: 1.5;
    --bs-btn-color: var(--bs-body-color);
    --bs-btn-border-width: var(--bs-border-width);
    --bs-btn-border-radius: 6px;
}

.userAccount {
    background-color: #1d2125 !important;
    border-color: #1d2125;
    padding: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.userAccount button {
    background-color: #384148;
    color: #9fadbc;
}
.ArchivedCardItem{
    border-radius: 8px;
    background-color: #22272b;
    box-shadow: var(--ds-shadow-raised, 0px 1px 1px #091e4240, 0px 0px 1px #091e424f);
    color: var(--ds-text, #b6c2cf);
    scroll-margin: 8px;
    overflow-wrap: break-word;
    white-space: normal;
}
.ArchivedCardtitle{
    font-size: 18px !important;
    margin: 0;
}
.ArchivedText{
    font-size: 14px !important;
    margin: 0;
}
.ArchivedCardItem{
    padding: 10px;
}