.Modal {
    background-color: #1e2225 !important;
    color: #b6c2cf !important;
}

.Main {
    border-radius: 3px;
    width: 390px;
    position: absolute;
    /* Ensure positioning within parent */
    margin: auto;
    top: 50%;
    /* Center vertically */
    left: 50%;
    /* Center horizontally */
    transform: translate(-50%, -50%);
}


.customModal {
    max-width: 350px;
    width: 100%;
}

.Button {
    min-width: max-content !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    color: #9fadbc !important;
    background-color: #38414868 !important;
    padding: 5px 10px;
    border-radius: 2px !important;
    border: 1px solid #808d97 !important;
}

.ButtonDelete {
    min-width: max-content !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    color: #cf3737 !important;
    background-color: #38414868 !important;
    padding: 5px 10px;
    border-radius: 2px !important;
    border: 1px solid #cf3737 !important;
}